import Button from "@material-ui/core/Button";
import * as React from "react";
import { Step, TooltipRenderProps } from "react-joyride";

import styled from "~/components/core/styled";
import { hexToRgba } from "~/utils/style";

interface Props extends TooltipRenderProps {
  continuous: boolean;
  index: number;
  isLastStep: boolean;
  size: number;
  step: Step;
}

const DashboardGuideStep: React.FC<Props> = ({
  primaryProps,
  skipProps,
  tooltipProps,
  index,
  step,
  size,
  isLastStep
}: Props): JSX.Element => (
  <Wrapper {...tooltipProps}>
    {`${index + 1}/${size}`}
    <Title>{step.title}</Title>
    <Content>{step.content}</Content>
    <Footer>
      <NextButton variant="contained" color="primary" {...primaryProps}>
        {isLastStep ? "done" : "next"}
      </NextButton>
      {!isLastStep && (
        <SkipButton color="primary" {...skipProps}>
          skip tutorial
        </SkipButton>
      )}
    </Footer>
  </Wrapper>
);

export default DashboardGuideStep;

const Wrapper = styled.div`
  background-color: ${({ theme }): string =>
    hexToRgba(theme.palette.common.black, 0.6)};
  border-radius: 8px;
  color: ${({ theme }): string => theme.palette.common.white};
  max-width: 420px;
  min-width: 290px;
  padding: 16px;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Content = styled.div`
  font-size: 14px;
  line-height: 1.7em;
  margin-top: 10px;
`;

const Footer = styled.footer`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const NextButton = styled(Button)`
  padding: 7px 43px;
  width: 122px;
`;

const SkipButton = styled(Button)`
  margin-top: 10px;
  width: 122px;
`;
