import { Paper } from "@material-ui/core";
import { AreaChart, DataSet, Scale, TooltipProps } from "@tutorme/area-chart";
import { format, max, min, parseISO } from "date-fns";
import React from "react";

import { theme } from "~/theme/theme";
import { DashboardApiQuery } from "~/utils/http";

import { useRequiredAuthContext } from "../auth/AuthWrapper";
import styled from "../core/styled";

type DashboardAreaChartProps = {
  apiQuery: DashboardApiQuery;
  label: string;
  data?: Array<{ dt: string; value: number }>;
};

function apiQueryScaleToChartScale(scale: DashboardApiQuery["scale"]): Scale {
  switch (scale) {
    case "hourly":
      return "hour";
    case "daily":
      return "day";
    case "weekly":
      return "week";
    case "monthly":
      return "month";
  }
}

const colorPairs = [
  {
    areaColor: theme.palette.data.turquoise[300],
    lineColor: theme.palette.data.turquoise[900]
  },
  {
    areaColor: theme.palette.data.blue[300],
    lineColor: theme.palette.data.blue[900]
  }
];

function areaChartDataToDatasets(
  labels: string[],
  data?: Array<{ dt: string; value: number; group?: number }>
): DataSet[] {
  if (!data?.length) {
    return [];
  }
  const datasets: DataSet[] = [];
  for (let idx = 0; ; idx++) {
    const items = data.filter(x => (x.group ?? 0) === idx);
    if (!items.length) {
      break;
    }
    datasets.push({
      ...colorPairs[idx],
      data: items.map(x => ({
        date: parseISO(x.dt),
        value: x.value
      })),
      label: labels[idx] ?? "Unknown"
    });
  }
  return datasets;
}

const CustomTooltip: React.FC<TooltipProps> = ({
  top,
  left,
  rows,
  datasetLabels
}: TooltipProps) => {
  const style = React.useMemo(
    () => ({
      top,
      left
    }),
    [top, left]
  );
  if (!rows.length) {
    return null;
  }
  return (
    <CustomTooltipRoot elevation={3} style={style}>
      <CustomTooltipTable>
        <tbody>
          <tr>
            <th>Date:</th>
            <td>{format(rows[0].date, "MM/dd/yy")}</td>
          </tr>
          {rows.map((row, idx) => (
            <tr key={idx}>
              <th>{datasetLabels[idx]}:</th>
              <td>
                {row.value === Math.round(row.value)
                  ? row.value.toFixed(0)
                  : row.value.toFixed(1)}
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTooltipTable>
    </CustomTooltipRoot>
  );
};

export const DashboardAreaChart: React.FC<DashboardAreaChartProps> = ({
  apiQuery,
  data,
  label
}) => {
  const {
    user: { weekStart }
  } = useRequiredAuthContext();
  const datasets = React.useMemo(
    () => areaChartDataToDatasets(label.split(","), data),
    [data, label]
  );
  const fromDt = React.useMemo(
    () =>
      min(
        [parseISO(apiQuery.fromDt)].concat(
          datasets.flatMap(dataset => dataset.data.map(row => row.date))
        )
      ),
    [apiQuery.fromDt, datasets]
  );
  const tillDt = React.useMemo(
    () =>
      max(
        [parseISO(apiQuery.tillDt)].concat(
          datasets.flatMap(dataset => dataset.data.map(row => row.date))
        )
      ),
    [apiQuery.tillDt, datasets]
  );

  return (
    <StyledAreaChart
      from={fromDt}
      to={tillDt}
      scale={apiQueryScaleToChartScale(apiQuery.scale)}
      datasets={datasets}
      isoWeekday={weekStart === 1}
      fontFamily="Rubik"
      tooltip={CustomTooltip}
    />
  );
};

const StyledAreaChart = styled(AreaChart)`
  height: 300px;
  min-width: 0;
  position: relative;
`;

const CustomTooltipRoot = styled(Paper)`
  pointer-events: none;
  position: absolute;
`;

const CustomTooltipTable = styled.table`
  background-color: white;
  font-family: "Rubik";
  font-size: 12px;
  padding: 10px;

  th {
    font-weight: normal;
    text-align: right;
  }

  td {
    font-family: "Rubik";
  }
`;
