import dayjs from "dayjs";
import React, { useRef } from "react";

import { PERIOD } from "~/constants/dates";
import {
  RANGE_GETTERS,
  SCALE,
  scaleFromString,
  SESSION,
  sessionFromString
} from "~/constants/filters";
import { DateOrNull } from "~/declarations/filters";
import { DashboardApiQuery } from "~/utils/http";
import { useIntersectionObserver } from "~/utils/useIntersectionObserver";
import {
  queryStateItem,
  TypeFromQueryStateConfig,
  useQueryState,
  ValueSetter
} from "~/utils/useQueryState";

import { useRequiredAuthContext } from "../auth/AuthWrapper";
import styled from "../core/styled";
import WidgetCard from "../core/WidgetCard/WidgetCard";
import { WidgetCardLoadingPlaceholder } from "../core/WidgetCard/WidgetCardContent";

const DASHBOARD_QUERY_STATE_CONFIG = {
  scale: queryStateItem<SCALE>(
    v => (v === SCALE.daily ? undefined : SCALE[v]),
    q => {
      if (q) {
        try {
          return scaleFromString(q);
        } catch {}
      }
      return SCALE.daily;
    }
  ),
  moderation: queryStateItem(
    (v: SESSION) => (v === SESSION.all ? undefined : "moderated"),
    q => {
      if (q) {
        try {
          return sessionFromString(q);
        } catch {}
      }
      return SESSION.all;
    }
  ),
  subject: queryStateItem(
    (v: number | null) => v?.toFixed(0) ?? undefined,
    q => (q && parseInt(q, 10)) || null
  ),
  period: queryStateItem(
    (v: PERIOD) => (v === PERIOD.thisMonth ? undefined : PERIOD[v]),
    q => {
      switch (q) {
        case "lastWeek":
          return PERIOD.lastWeek;
        case "thisWeek":
          return PERIOD.thisWeek;
        case "lastMonth":
          return PERIOD.lastMonth;
        case "custom":
          return PERIOD.custom;
        default:
          return PERIOD.thisMonth;
      }
    }
  ),
  fromDt: queryStateItem(
    (v: DateOrNull) => v?.toISOString() ?? undefined,
    q => (q?.length ? dayjs(q) : null)
  ),
  tillDt: queryStateItem(
    (v: DateOrNull) => v?.toISOString() ?? undefined,
    q => (q?.length ? dayjs(q) : null)
  )
};

type DashboardQueryContextType = [
  TypeFromQueryStateConfig<typeof DASHBOARD_QUERY_STATE_CONFIG>,
  ValueSetter<typeof DASHBOARD_QUERY_STATE_CONFIG>,
  DashboardApiQuery
];

const DashboardQueryStateContext = React.createContext<
  DashboardQueryContextType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(undefined as any);

export function useDashboardQueryState() {
  const context = React.useContext(DashboardQueryStateContext);
  if (typeof context === "undefined") {
    throw new Error("DashboardQueryContext isnt available");
  }
  return context;
}

export function useDashboardApiQuery() {
  const [, , apiQuery] = useDashboardQueryState();
  return apiQuery;
}

export const DashboardQueryStateProvider: React.FC = ({ children }) => {
  const { selectedUmbrella } = useRequiredAuthContext();
  const [queryState, setQueryState] = useQueryState(
    DASHBOARD_QUERY_STATE_CONFIG
  );
  const contextValue = React.useMemo<DashboardQueryContextType>(() => {
    const range: [DateOrNull, DateOrNull] =
      queryState.period === PERIOD.custom
        ? [queryState.fromDt, queryState.tillDt]
        : RANGE_GETTERS[queryState.period]();

    return [
      queryState,
      setQueryState,
      {
        lessons: SESSION[queryState.moderation],
        umbrellaAccountId: selectedUmbrella.umbrellaAccName,
        fromDt: range[0]?.toISOString() ?? "",
        tillDt: range[1]?.toISOString() ?? "",
        scale: SCALE[queryState.scale],
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        subjects: queryState.subject?.toFixed(0) ?? undefined
      }
    ];
  }, [queryState, selectedUmbrella.umbrellaAccName, setQueryState]);
  return (
    <DashboardQueryStateContext.Provider value={contextValue}>
      {children}
    </DashboardQueryStateContext.Provider>
  );
};

export type DashboardLazyWidgetProps = {
  apiQuery: DashboardApiQuery;
  visible: boolean;
};

export type DashboardLazyWidgetImpl = React.ForwardRefExoticComponent<
  DashboardLazyWidgetProps & React.RefAttributes<HTMLElement>
>;

function useDashboardLazy(intersecionDebugName?: string) {
  const apiQuery = useDashboardApiQuery();
  const frozenApiQuery = useRef(apiQuery);
  const [ref, visible] = useIntersectionObserver(intersecionDebugName);
  if (visible) {
    frozenApiQuery.current = apiQuery;
  }
  const showContent = React.useRef(visible);
  if (visible) {
    showContent.current = true;
  }
  return {
    visible,
    ref,
    showContent: showContent.current,
    apiQuery: frozenApiQuery.current
  };
}

type LazyDashboardComponentProps = {
  intersecionDebugName?: string;
  placeholder: React.ReactElement;
  render: (
    apiQuery: DashboardApiQuery,
    ref: (value: HTMLElement | null) => void,
    visible: boolean
  ) => JSX.Element;
};

export function LazyDashboardComponent({
  intersecionDebugName,
  render,
  placeholder
}: LazyDashboardComponentProps) {
  const { showContent, visible, ref, apiQuery } = useDashboardLazy(
    intersecionDebugName
  );
  if (!showContent) {
    return React.cloneElement(placeholder, { ref });
  }
  return render(apiQuery, ref, visible);
}

const StyledWidgetCardHack = styled(WidgetCard)``;

export type CreateDashboardLazyWidgetProps = {
  displayName: string;
  clarification: string;
  caption: string;
  contentHeight: number;
  useRender: (apiQuery: DashboardApiQuery) => React.ReactElement | null;
  customWidgetCard?: typeof StyledWidgetCardHack;
  headerEndAdornment?: React.ReactNode;
};

export function createDashboardLazyWidget({
  displayName,
  clarification,
  caption,
  contentHeight,
  useRender,
  customWidgetCard,
  headerEndAdornment
}: CreateDashboardLazyWidgetProps) {
  const Comp: React.FC<{ apiQuery: DashboardApiQuery }> = ({ apiQuery }) => {
    return useRender(apiQuery);
  };
  Comp.displayName = displayName;

  const Lazy: React.FC = function Lazy() {
    const { showContent, apiQuery, ref } = useDashboardLazy(displayName);
    const WidgetCardComp = customWidgetCard ?? WidgetCard;
    return (
      <WidgetCardComp
        contentHeight={contentHeight}
        caption={caption}
        clarification={clarification}
        headerEndAdornment={headerEndAdornment}
        ref={ref}
        wrapContent={false}
      >
        {showContent ? (
          <Comp apiQuery={apiQuery} />
        ) : (
          <WidgetCardLoadingPlaceholder />
        )}
      </WidgetCardComp>
    );
  };

  return Lazy;
}
