import dayjs from "dayjs";
import * as React from "react";

import HourglassIcon from "~/assets/icons/Hourglass.svg?sprite";
import InfinityIcon from "~/assets/icons/Infinity.svg?sprite";
import TimeIcon from "~/assets/icons/Time.svg?sprite";
import { useRequiredAuthContext } from "~/components/auth/AuthWrapper";
import StatisticCard from "~/components/core/StatisticCard";
import StatisticCardSkeleton from "~/components/core/StatisticCard/StatisticCardSkeleton";
import styled from "~/components/core/styled";
import { PARTNERS_EMAIL } from "~/constants/common";
import { DATE_FORMAT_FULL_YEAR, MINUTES_IN_HOUR } from "~/constants/dates";
import { UMBRELLA_BILLING_MODE } from "~/declarations/models/UmbrellaAccount";
import { useAuthenticatedFetch } from "~/utils/http";

const MAIL_TO_SUBJECT = "Purchase More Hours";

const DashboardHoursRemaining: React.FC = (): JSX.Element => {
  const {
    selectedUmbrella: {
      umbrellaAccName,
      billingMode,
      expirationDate,
      timeLimitMinutes
    }
  } = useRequiredAuthContext();

  const { data: hoursUsedRes, loading } = useAuthenticatedFetch(
    "/api/v1/new_stats/hours_used/",
    {
      umbrellaAccountId: umbrellaAccName
    },
    true
  );

  const totalHours = timeLimitMinutes / MINUTES_IN_HOUR;
  const usedHours = hoursUsedRes?.hoursUsed ?? 0;
  const remainingHours = totalHours - usedHours;

  if (loading) {
    return <StatisticCardSkeleton />;
  }

  switch (billingMode) {
    case UMBRELLA_BILLING_MODE.unlimited:
      return (
        <StyledStatisticCard
          avatar={{
            Icon: TimeIcon,
            color: "primary"
          }}
          caption="You are on our equity plan"
          value={<StyledInfinityIcon />}
          link={{
            text: `Expiration date: ${dayjs(expirationDate).format(
              DATE_FORMAT_FULL_YEAR
            )}`
          }}
          clarification="Your organization is enrolled in our equity plan with unlimited hours."
        />
      );
    case UMBRELLA_BILLING_MODE.prepaid:
      return (
        <StyledStatisticCard
          avatar={{
            Icon: HourglassIcon,
            color: "primary"
          }}
          caption="Hours remaining"
          value={Math.round(remainingHours)}
          link={{
            text: ""
          }}
          clarification="These are your remaining hours. Please contact your Customer Success Manager to purchase more."
        />
      );

    case UMBRELLA_BILLING_MODE.postpaid:
    default:
      return (
        <StyledStatisticCard
          avatar={{
            Icon: TimeIcon,
            color: "primary"
          }}
          caption="Hours Used"
          value={usedHours}
          link={{
            // text: "This Billing Period",
            text: ""
          }}
          clarification="Your organization is enrolled in our Post-Paid plan. These are the hours used so far."
        />
      );
  }
};

export default DashboardHoursRemaining;

const StyledStatisticCard = styled(StatisticCard)`
  background-color: ${({ theme }): string => theme.palette.grey[50]};
`;

const StyledInfinityIcon = styled(InfinityIcon)`
  height: 34px;
  width: 80px;
`;
