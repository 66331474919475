import styled from "~/components/core/styled";

export const StatisticsWrapper = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px 0;

  & > *:not(:last-child) {
    margin-right: 36px;
  }

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.md}px) {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: unset;
    }
  }

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    padding: 0 10px;
  }
`;
