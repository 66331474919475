import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";

import styled from "~/components/core/styled";

const StatisticCardSkeleton = React.forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>(({ children }, ref) => {
  return (
    <Wrapper ref={ref}>
      <AvatarSkeleton
        variant="circle"
        width={65}
        height={65}
        animation={children ? false : "pulse"}
      />
      <CardSkeleton
        width="100%"
        height={170}
        animation={children ? false : "pulse"}
      >
        {children && <Error>{children}</Error>}
      </CardSkeleton>
    </Wrapper>
  );
});

export default StatisticCardSkeleton;

const Error = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.palette.error.main};
  display: flex;
  height: 100%;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 30px;
`;

const CardSkeleton = styled(Skeleton)`
  border-radius: 16px;
  transform: none;
`;

const AvatarSkeleton = styled(Skeleton)`
  margin-top: -25px;
  position: absolute;
  transform: none;
`;
