import React from "react";

import { UmbrellaAccount } from "~/declarations/models/UmbrellaAccount";
import { User } from "~/declarations/models/User";

export interface AppContextProps {
  user?: User;
  loading: boolean;
  update?: () => void;
  selectedUmbrella?: UmbrellaAccount;
  onGuidePass?: (passed: boolean) => void;
  onSelectUmbrella?: (umbrellaAccount: UmbrellaAccount) => void;
}

export default React.createContext<AppContextProps>({
  loading: true
});
