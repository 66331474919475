import React from "react";

import AppContext from "~/components/providers/AppContext";
import { PERMISSION_CODENAME } from "~/declarations/models/User";
import { isEmpty } from "~/utils/common";

export default function(
  codename?: PERMISSION_CODENAME
): { loading: boolean; granted: boolean } {
  const { user, loading: userLoading } = React.useContext(AppContext);
  const [initiated, setInitiated] = React.useState(false);

  const userPermissions = user?.userPermissions;
  const fullAccess = isEmpty(userPermissions);

  React.useEffect(() => {
    if (!initiated && userPermissions) {
      setInitiated(true);
    }
  }, [userPermissions, initiated]);

  const accessPermissions = React.useMemo(
    () => userPermissions?.map(({ codename }) => codename) || [],
    [userPermissions]
  );

  const granted = React.useMemo(
    () =>
      Boolean(fullAccess || (codename && accessPermissions.includes(codename))),
    [codename, fullAccess, accessPermissions]
  );

  return {
    loading: !initiated && userLoading && !userPermissions,
    granted: granted
  };
}
