import React from "react";

import styled from "~/components/core/styled";

interface Props {
  color: string;
  text: string;
}

const LegendItem: React.FC<Props> = ({ color, text }: Props): JSX.Element => {
  return (
    <>
      <LegendColor style={{ backgroundColor: color }} />
      <LegendText>{text}</LegendText>
    </>
  );
};

export default LegendItem;

const LegendText = styled.div`
  font-size: 14px;
  margin-left: 10px;
  text-align: center;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    font-size: 9px;
  }
`;

const LegendColor = styled.div`
  border-radius: 50%;
  height: 8px;
  margin-left: 20px;
  width: 8px;
`;
