import Avatar, { AvatarProps } from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import NextLink from "next/link";
import { omit } from "ramda";
import * as React from "react";
import { O } from "ts-toolbelt";

import styled from "~/components/core/styled";
import DashboardContext from "~/components/dashboard/DashboardContext";
import { theme } from "~/theme/theme";
import {
  ApiTypes,
  DashboardApiQuery,
  useAuthenticatedFetch
} from "~/utils/http";
import { localeFormat } from "~/utils/numbers";
import { useLinkWithUmbrella } from "~/utils/useLinkWithUmbrella";

import ClarificationIcon from "../ClarificationIcon";
import StatisticCardSkeleton from "./StatisticCardSkeleton";

type AvailableColors = "primary" | "secondary";

const STUB_VALUE = "—";

interface StatisticCardLinkProps {
  isMailTo?: boolean;
  href?: string;
  text: string;
}

export interface StatisticCardProps {
  granted?: boolean;
  loading?: boolean;
  className?: string;
  avatar: {
    Icon: React.ComponentType;
    color: AvailableColors | string;
  };
  value?: React.ReactNode;
  caption: string;
  link: StatisticCardLinkProps;
  clarification?: string;
  formatter?: (value: unknown) => React.ReactNode;
}

const StatisticCard = React.forwardRef<HTMLDivElement, StatisticCardProps>(
  (
    {
      avatar,
      className,
      value,
      caption,
      link,
      clarification,
      formatter,
      loading = false,
      granted
    },
    ref
  ) => {
    const { Icon, color } = avatar;

    const valueToOutput = (): React.ReactNode => {
      if (formatter) {
        return formatter(value);
      }

      if (!value) {
        return STUB_VALUE;
      }

      return typeof value === "number" ? localeFormat(value) : value;
    };

    if (loading) {
      return <StatisticCardSkeleton ref={ref} />;
    }

    return (
      <Wrapper elevation={3} className={className} ref={ref}>
        <CardHeader>
          <CardAvatar color={color}>
            <Icon />
          </CardAvatar>
          <StyledClarificationIcon clarification={clarification} />
        </CardHeader>

        <CardContent>
          <CardValue variant="h3">{valueToOutput()}</CardValue>

          <CardFooter>
            <Caption variant="body1">{caption}</Caption>

            <StatisticCardLink {...link} granted={granted} />
          </CardFooter>
        </CardContent>
      </Wrapper>
    );
  }
);

export default StatisticCard;

export function statisticsProvider<K extends keyof ApiTypes>(
  url: K,
  getValue: (data: ApiTypes[K]["response"]) => number | undefined
) {
  return {
    url,
    getValue: getValue as (
      data: ApiTypes[keyof ApiTypes]["response"]
    ) => number | undefined
  };
}

type ApiStatisticCardProps = O.Merge<
  Omit<StatisticCardProps, "loading" | "value" | "avatar" | "link">,
  {
    provider: {
      url: keyof ApiTypes;
      getValue: (
        data: ApiTypes[keyof ApiTypes]["response"]
      ) => number | undefined;
    };
    icon: React.FC;
    apiQuery: Omit<DashboardApiQuery, "scale" | "tz">;
  }
>;

export const ApiStatisticCard = React.forwardRef<
  HTMLDivElement,
  ApiStatisticCardProps
>(({ provider, icon, apiQuery, ...props }, ref) => {
  const { selectedPeriod } = React.useContext(DashboardContext);
  const iconColor = theme.palette.data.turquoise[900];
  const selectedPeriodTitle = selectedPeriod?.title || "";

  const cleanQuery = omit(["scale", "tz"], apiQuery);
  const { url, getValue } = provider;
  const { data, loading, error } = useAuthenticatedFetch(url, cleanQuery, true);

  if (error) {
    return (
      <StatisticCardSkeleton ref={ref}>
        {url}
        <br />
        {error.toString()}
      </StatisticCardSkeleton>
    );
  }
  if (loading) {
    return <StatisticCardSkeleton ref={ref} />;
  }
  return (
    <StatisticCard
      ref={ref}
      {...props}
      link={{
        text: selectedPeriodTitle
      }}
      avatar={{
        Icon: icon,
        color: iconColor
      }}
      loading={loading}
      value={typeof data !== "undefined" ? getValue(data) : undefined}
    />
  );
});

type StatisticCardLinkComponentProps = StatisticCardLinkProps & {
  granted: boolean | undefined;
};

const StatisticCardLink: React.FC<StatisticCardLinkComponentProps> = ({
  href,
  text,
  isMailTo,
  granted
}: StatisticCardLinkComponentProps) => {
  const linkWithUmbrella = useLinkWithUmbrella(href);

  if (isMailTo) {
    return (
      <StyledLink href={href} target="_blank">
        {text}
      </StyledLink>
    );
  }

  if (granted && linkWithUmbrella) {
    return (
      <NextLink href={linkWithUmbrella}>
        <StyledLink href={linkWithUmbrella?.pathname}>{text}</StyledLink>
      </NextLink>
    );
  }

  return <Subtext variant="body2">{text}</Subtext>;
};

const Wrapper = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 30px;
  min-height: 155px;
  overflow: visible;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.md}px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }): string => theme.palette.primary.main} !important;
`;

const CardHeader = styled.header`
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledClarificationIcon = styled(ClarificationIcon)`
  position: absolute;
  right: 1px;
  top: 10px;
`;

interface CardAvatarProps extends AvatarProps {
  color: AvailableColors | string;
}

const CardAvatar = styled(Avatar)<CardAvatarProps>`
  background-color: ${({ theme, color }): string | undefined => {
    if (color) {
      return theme.palette[color as AvailableColors]?.main || color;
    }
  }};
  height: 60px;
  margin-top: -25px;
  width: 60px;

  svg {
    width: 30px;
  }
`;

const Subtext = styled(Typography)`
  color: ${({ theme }): string => theme.palette.grey[600]};
  line-height: 10px;
`;

const Caption = styled(Typography)`
  font-weight: 700;
  margin-bottom: 5px;
`;

const CardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  text-align: center;
`;

const CardValue = styled(Typography)`
  font-size: 3rem;
  margin: 10px 0;
`;

const CardFooter = styled.footer`
  line-height: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;
