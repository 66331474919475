import React from "react";

import styled from "~/components/core/styled";

import LegendItem from "./LegendItem";

export interface LegendDataModel {
  color: string;
  text: string;
}

interface Props {
  legendData: LegendDataModel[];
}

const Legend: React.FC<Props> = ({ legendData }: Props): JSX.Element => {
  return (
    <LegendContainer>
      {legendData &&
        legendData.map(({ color, text }: LegendDataModel) => (
          <LegendItem key={color} color={color} text={text} />
        ))}
    </LegendContainer>
  );
};

export default Legend;

const LegendContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;
  margin-right: 20px;
`;
